import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import fiveStars from '../assets/images/fivestars.png'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

export default function SwiperComponent2() {
  return (
    <>
      <Swiper pagination={true} modules={[Pagination]} className="">
        <SwiperSlide className="mb-16">                
        <div className="third_div_review_card">
                    <div className="third_div_review_card_header">
                        <div className="ml-4 mt-2 third_div_review_name"><i>Emily</i></div>
                        <img className="mr-4 mt-2 third_div_review_stars" src={fiveStars} alt="Showing an example of personalized recommendations from the app"/>
                    </div>
                    <div className="third_div_review_content">
                    Honestly, without Lumos I might have given up on the whole insurance thing. They were able to give me logical, data based advice for the best plan for me. 
                    Everything about health insurance is a pain, but Lumos is a helpful partner in sorting through all of the confusion and offering straightforward, trustworthy guidance. Thank you!
                    </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="mb-16">                
        <div className="third_div_review_card">
                    <div className="third_div_review_card_header">
                        <div className="ml-4 mt-2 third_div_review_name"><i>Gabriel</i></div>
                        <img className="mr-4 mt-2 third_div_review_stars" src={fiveStars} alt="Showing an example of personalized recommendations from the app"/>
                    </div>
                    <div className="third_div_review_content">
                    The Lumos team were incredibly helpful to my family and me. We never felt like we were doing the wrong thing or making a decision that would come back to bite us. We're 
                    eternally grateful for all that the Lumos Health team has done for us and will happily use them every year from now on.
                    </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="mb-16">                
          <div className="third_div_review_card slide">
                    <div className="third_div_review_card_header">
                        <div className="ml-4 mt-2 third_div_review_name"><i>Luis</i></div>
                        <img className="mr-4 mt-2 third_div_review_stars" src={fiveStars} alt="Showing an example of personalized recommendations from the app"/>
                    </div>
                    <div className="third_div_review_content">
                    Finding the appropriate medical insurance has always been a problem for me. The result was a much cheaper insurance than the one I had been using for years and one that covered all my needs. Great site. Highly recommend!                    </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="mb-16">                
          <div className="third_div_review_card slide">
                    <div className="third_div_review_card_header">
                        <div className="ml-4 mt-2 third_div_review_name"><i>Erika</i></div>
                        <img className="mr-4 mt-2 third_div_review_stars" src={fiveStars} alt="Showing an example of personalized recommendations from the app"/>
                    </div>
                    <div className="third_div_review_content">
                    Lumos Health prioritizes the needs of their clients. What made my experience great is that the Lumos Health team spent the time I needed to feel comfortable with the healthcare decision process; this company demonstrates they are focused and dedicated to supporting people in their healthcare decision journey.</div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="mb-16">                
          <div className="third_div_review_card slide">
                    <div className="third_div_review_card_header">
                        <div className="ml-4 mt-2 third_div_review_name"><i>David</i></div>
                        <img className="mr-4 mt-2 third_div_review_stars" src={fiveStars} alt="Showing an example of personalized recommendations from the app"/>
                    </div>
                    <div className="third_div_review_content">
                    Great Experience all around. The Lumos App helped me understand exactly what I needed and I felt confident that I was making the right choice. The experience with actually enrolling for a health plan couldn't have been easier. I would absolutely recommend them to anyone who needs to pick out health insurance!</div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="mb-16">                
          <div className="third_div_review_card slide">
                    <div className="third_div_review_card_header">
                        <div className="ml-4 mt-2 third_div_review_name"><i>Oke</i></div>
                        <img className="mr-4 mt-2 third_div_review_stars" src={fiveStars} alt="Showing an example of personalized recommendations from the app"/>
                    </div>
                    <div className="third_div_review_content">
                    Lumos saved me hours of work during my health insurance enrollment. Lumos helped me choose a health insurance plan by suggesting a few through their data analyses. Then they sat down with me to make sure I was enrolled in the plan before the deadline. Definitely helpful for people who don't have time to conduct hours of research!</div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
