import React from 'react';
import {Helmet} from 'react-helmet'

function LicensingPage({ data }) {
  return (
    <div className="flex flex-col items-center">
      <Helmet>
          <title>Licensing</title>
          <meta name="description" content="We are a licensed brokerage serving multiple federal marketplace states." />
          <meta name="keywords" content="health insurance, ACA plans, marketplace plans, subsidies, true cost, obamacare" />
      </Helmet>
      <h1 className="text-3xl font-semibold text-lumos-blue mt-10 mb-10">Insurance Licenses</h1>
      <table className="w-11/12 max-w-4xl">
        <thead className="text-left text-lumos-blue text-base bg-lumos-gray border-black border-t-2 border-b-2">
          <tr>
            <th className="py-1 px-2">State</th>
            <th className="py-1 px-2">License</th>
            <th className="py-1 px-2">Name</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="py-4 px-2 border-slate-200 border-b-2 text-sm">{row.state}</td>
              <td className="py-4 px-2 border-slate-200 border-b-2 text-sm">{row.licenseNumber}</td>
              <td className="py-4 px-2 border-slate-200 border-b-2 text-sm">{row.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LicensingPage;