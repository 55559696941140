import React from "react";
import fiveStars from '../assets/images/fivestars.png'

export default function CarouselItem({ name, review_text }) {
  return (
    <div className="third_div_review_card">
        <div className="third_div_review_card_header">
            <div className="ml-4 mt-2 third_div_review_name"><i>{name}</i></div>
            <img className="mr-4 mt-2 third_div_review_stars" src={fiveStars} alt="Showing an example of personalized recommendations from the app"/>
        </div>
        <div className="ml-2 mt-5 px-2">
            {review_text}    
        </div>
    </div>
  );
}