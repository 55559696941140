import logo from './assets/images/logo3.svg';

import LandingPage from './components/Landing';
import AboutPage from './components/AboutPage';
import LicensingPage from './components/LicensingPage'
import PromoPage from './components/PromoPage';
import DropdownMenu from './components/Dropdown';

import React, {useState } from 'react';
import { Route, Routes, Link} from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import usePageTracking from './hooks/usePageTracking';
import { licenseData } from "./components/licensing-data";
import { H } from 'highlight.run';

/**
 * React component defining the header and routes.
 * @returns {JSX} - Root component
 */
export default function App() {

  usePageTracking();
  H.init('kevyow5e', {
    serviceName: "lumos-landing-page",
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        "https://www.googleapis.com/identitytoolkit",
        "https://securetoken.googleapis.com",
      ],
    },
  });
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="app">
      <header className="header-bar flex flex-row justify-between items-center py-2">
          <div className='flex flex-row items-center pt-2'>
            <a href="https://www.lumos-health.com"><img src={logo} className="header-img-logo p-2 ml-4 mt-1 mb-1" alt="lumos logo"/></a>
          </div>
          <div className='header-right-items'>
              <Link className="lumos-blue header-options py-2" to="/about">About</Link>
              <a href="https://www.blog.lumos-health.com" className="lumos-blue header-options py-2" target="_blank" rel="noreferrer">Blog</a>
              <a href="https://www.brokerapp.lumos-health.com" className="lumos-blue header-options py-2" target="_blank" rel="noreferrer">For Brokers</a>
              <button className="header-get-started-btn">
                <a href="https://www.marketplaceapp.lumos-health.com" className="text-white header-get-started" target="_blank">Get started</a>
              </button>
          </div>
          <div className='header-menu-button'>
          <DropdownMenu isOpen={isDropdownOpen} />
            <div className={`menu-toggle ${isDropdownOpen ? 'active' : ''}`}
              onClick={() => setDropdownOpen(!isDropdownOpen)}>
              &#9776;
            </div>
            </div>
      </header>
      <main onClick={() => setDropdownOpen(false)}>
          <Routes>
              <Route exact path="/" element={<LandingPage/>} />
              <Route path="/about" element={<AboutPage/>} />
              <Route path="/licenses" element={<LicensingPage data={licenseData}/>} />
              <Route path="/promo" element={<PromoPage/>} />
          </Routes>
      </main>
      <footer class="bg-white footer_space dark:bg-gray-800">
      <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span class="text-xs text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://flowbite.com/" class="hover:underline">Lumos Health™</a>. All Rights Reserved.</span>
    <ul class="flex flex-wrap items-center footer_space text-xs font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
              <a href="#" class="mr-4 hover:underline md:mr-6">Privacy Policy</a>
          </li>
          <li>
              <Link className="mr-4 hover:underline md:mr-6" to="/licenses">Licensing</Link>
          </li>
          <li>
              <a href = "mailto: contact@lumos-health.com" target="_blank" class="hover:underline">Contact</a>
          </li>
    </ul>
  </div>
  </footer>
  </div>
  );
}
