import React from "react";
import { cardDetails } from "./carousel-config";
import CarouselItem from "./CarouselItem";

export default function AutoplayCarousel() {
  return (
    <div className="carousel-container">
      <div className="carousel-track">
        {Object.keys(cardDetails).map((detailKey) => {
          return (
            <CarouselItem
              name={cardDetails[detailKey].name}
              review_text={cardDetails[detailKey].review_text}
            ></CarouselItem>
          );
        })}
        {Object.keys(cardDetails).map((detailKey) => {
          return (
            <CarouselItem
              name={cardDetails[detailKey].name}
              review_text={cardDetails[detailKey].review_text}
            ></CarouselItem>
          );
        })}
      </div>
    </div>
  );
}