import Typewriter from "typewriter-effect";

function TypingEffect() {
  return (
    <Typewriter
      options={{
        strings: ['scary.', 'time-consuming.', 'overwhelming.'],
        pauseFor: 1000,
        deleteSpeed: 100,
        autoStart: true,
        loop: true, // Set loop to true
      }}
    />
  );
}

export default TypingEffect;