import React from 'react';
import avatarImage from '../assets/images/avatars.png'
import {Helmet} from 'react-helmet'

function AboutPage() {
    return (
        <div>
        <Helmet>
            <title>About Us</title>
            <meta name="description" content="We created Lumos to bring transparency into shopping for ACA plans." />
            <meta name="keywords" content="health insurance, ACA plans, marketplace plans, subsidies, true cost, obamacare" />
        </Helmet>
        <div className="about_page_desktop">
        <div className="about_page_text">
            <div className="about_page_title">
                Mission
                <div className="about_page_details">
                We have an ambitious goal: to bring price transparency to all areas of healthcare.  Our first area of focus is health insurance. <br></br><br></br>
                Today, consumers navigate hundreds of plans with dizzying levels of details but without a clear understanding of the true cost of each plan. As a result, Americans overpay by billions of dollars each year. <br></br><br></br>
                At Lumos Health, we are building a solution that cuts through the noise, and lets consumers and brokers know the true-cost of each plan before they buy. 
                </div>
            </div>
            <div className="about_page_title mt-8">
                Our Team
                <div className="about_page_details">
                We are both data-nerds, economists, and big on personal finance. We are passionate about helping customers make informed decisions that save them money, and improves the US healthcare industry as a result.
                </div>
            </div>
        </div>
        <div className="about_page_image">
            <img className="about_page_avatar" src={avatarImage} alt="avatar of lumos team"></img>
        </div>
      </div>
        <div className="about_page_mobile">
            <div className="about_page_text">
                <div className="about_page_title">
                    Mission
                    <div className="about_page_details">
                    We have an ambitious goal: to bring price transparency to all areas of healthcare.  Our first area of focus is health insurance. <br></br><br></br>
                    Today, consumers navigate hundreds of plans with dizzying levels of details but without a clear understanding of the true cost of each plan. As a result, Americans overpay by billions of dollars each year. <br></br><br></br>
                    At Lumos Health, we are building a solution that cuts through the noise, and lets consumers and brokers know the true-cost of each plan before they buy. 
                    </div>
                </div>
                <div className="about_page_title mt-8">
                    Our Team
                    <div className="about_page_details">
                    We are both data-nerds, economists, and big on personal finance. We are passionate about helping customers make informed decisions that save them money, and improves the US healthcare industry as a result.
                    </div>
                </div>
            </div>
            <div className="about_page_image">
                <img className="about_page_avatar" src={avatarImage} alt="avatar of lumos team"></img>
            </div>
        </div>
        </div>
    );
  }
  
  export default AboutPage;