export const cardDetails = {
    0: {
        name: "Emily",
        review_text: "Honestly, without Lumos I might have given up on the whole insurance thing. They were able to give me logical, data based advice for the best plan for me."
    },

    1: {
        name: "Gabriel",
        review_text: "The Lumos team were incredibly helpful to my family and me. We never felt like we were doing the wrong thing or making a decision that would come back to bite us. We're grateful for all that the Lumos Health team has done for us and will happily use them every year from now on."
    },

    2: {
        name: "Luis",
        review_text: "Finding the appropriate medical insurance has always been a problem for me. The result was a much cheaper insurance than the one I had been using for years and one that covered all my needs. Great site. Highly recommend!                    "
    },

    3: {
        name: "Erika",
        review_text: "Lumos Health prioritizes the needs of their clients. What made my experience great is that the Lumos Health team spent the time I needed to feel comfortable with the healthcare decision process."
    },

    4: {
        name: "David",
        review_text: "Great Experience all around. The Lumos App helped me understand exactly what I needed and I felt confident that I was making the right choice. I would absolutely recommend them to anyone who needs to pick out health insurance!"
    },

    5: {
        name: "Oke",
        review_text: "Lumos saved me hours of work during my health insurance enrollment. Lumos helped me choose a health insurance plan by suggesting a few through their data analyses. Then they sat down with me to make sure I was enrolled in the plan before the deadline."
    },
}