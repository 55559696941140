import React from 'react';

import insurersImage from '../assets/images/insurers.png'
import subsidiesImage from '../assets/images/subsidies_desktop.png'
import trueCostImage from '../assets/images/truecost.svg'
import recommendedImage from '../assets/images/recommended4.png'
import enrollImage from '../assets/images/enroll.png'
import SwiperComponent from './SwipeReasons';


export default function AboutPage() {
    return (
    <div>
        <div className='first_div'>
            <div className="first_div_base_hook typing_desktop">
                We're very confident.
                <div className="first_div_subhook mt-5">
                    Find a better plan with Lumos or we'll send you a $20 Starbucks gift card!* 
                </div>
                <button className="firstdiv-get-started-btn mt-10">
                    <a href="https://www.marketplaceapp.lumos-health.com">Get started</a>
                </button>
                <div className='w-[60vw] mx-auto mt-5'>
                    <p className='text-center text-sm text-slate-800 font-normal'>
                    * Must have been enrolled in a 2023 Health Insurance Marketplace® plan and allow us to verify this. Gift card awarded if, after reviewing plan options with a Lumos representative, you still re-enroll in the same plan you had in 2023.  Offer valid through Jan 15, 2024.
                    </p>
                </div>         
            </div>

            <div className="first_div_base_hook typing_mobile">
                We're very confident.
                <div className="first_div_subhook mt-5">
                    Find a better plan with Lumos or we'll send you a $20 Starbucks gift card!* 
                </div>
                <button className="firstdiv-get-started-btn mt-10">
                    <a href="https://www.marketplaceapp.lumos-health.com">Get started</a>
                </button>
                <div className='mt-5'>
                    <p className='text-xs text-slate-800 font-normal'>
                    * Must have been enrolled in a 2023 Health Insurance Marketplace® plan and allow us to verify this. Gift card awarded if, after reviewing plan options with a Lumos representative, you still re-enroll in the same plan you had in 2023.  Offer valid through Jan 15, 2024.
                    </p>
                </div>  
            </div>
        </div>

        <div className="second_div">
            <div className="second_div_main_title">
                Here's what easy looks like
            </div>
            <div classname="second_div_easy_reasons">
                <div className="second_div_reason1">
                    <img className="second_div_reason1_img" src={insurersImage} alt="logos of health insurance issuers"/>
                    <div className="second_div_reason1_text">
                        Shop plans across all major insurers<br></br><p className="second_div_reason1_subtext">We work with Healthcare.gov to give<br></br> you access to all ACA plans at the same prices.</p>
                    </div>
                </div>
                <div className="second_div_reason2">
                    <div className="second_div_reason2_text">
                    See how much you qualify for in <br></br>subsidies<br></br><p className="second_div_reason2_subtext">It takes less than a minute.</p>
                    </div>
                    <img className="second_div_reason2_img" src={subsidiesImage} alt="Showing example of subsidies user may qualify for"/>
                </div>
                <div className="second_div_reason3">
                    <img className="second_div_reason3_img" src={trueCostImage} alt="Showing an equation of true cost"/>
                    <div className="second_div_reason3_text">
                        Save money by shopping based on the true <br></br>cost of each plan<p className="second_div_reason3_subtext">Using our propietary dataset and algorithms, we estimate 
                        how much you should expect to spend in out-of-pocket expenses on each and every plan based on hundreds of individuals and families similar to you.</p>
                    </div>
                </div>
                <div className="second_div_reason4">
                    <div className="second_div_reason4_text">
                        Get personalized recommendations <br></br> and shop across all plans<br></br><p className="second_div_reason4_subtext">Lumos' algorithms recommends plans with the lowest true cost across categories. Shop, review, compare true cost and coverage across these recommended plans and
                        all your plans</p>
                    </div>
                    <img className="second_div_reason4_img" src={recommendedImage} alt="Showing an example of personalized recommendations from the app"/>
                </div>
                <div className="second_div_reason6">
                    <img className="second_div_reason6_img" src={enrollImage} alt="Showing an example of personalized recommendations from the app"/>
                    <div className="second_div_reason6_text">
                    Enroll only when you're ready <p className="second_div_reason6_subtext">We prioritize your peace of mind and will never overwhelm you with messages. After selecting a plan, you'll receive an enrollment link, and we'll be at your service whenever you need assistance.</p>
                    </div>
                </div>
            </div>

            <button className="firstdiv-get-started-btn mt-10 mx-auto text-xl">
                    <a href="https://www.marketplaceapp.lumos-health.com">Get started</a>
            </button>
        </div>

        <div className="second_div_mobile">
            <div className="second_div_main_title">
                Here's what easy looks like
            </div>
            <SwiperComponent></SwiperComponent>

            <button className="firstdiv-get-started-btn mt-10 mx-auto text-xl">
                    <a href="https://www.marketplaceapp.lumos-health.com">Get started</a>
            </button>
        </div>
    </div>
  )
}