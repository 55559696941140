import React from "react";
import 'flowbite';
 
export default function Accordion() { 
  return (
        <div id="accordion-collapse" data-accordion="collapse">
        <h2 id="accordion-collapse-heading-1">
            <button type="button" class="flex items-center justify-between faq_width faq_hover p-4 font-medium text-left dark:text-lumos-blue text-lumos-blue faq_question border border-b-0 border-gray-400 rounded-t-xl" data-accordion-target="#accordion-collapse-body-1" aria-expanded="false" aria-controls="accordion-collapse-body-1">
            <span>Is Lumos free to use? How does Lumos make money?</span>
            <svg data-accordion-icon class="faq_arrow rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-1" class="hidden" aria-labelledby="accordion-collapse-heading-1">
            <div class="p-5 faq_width border border-b-0 border-gray-200">
            <p class="mb-2 faq_answer dark:text-gray-500 text-gray-500">Lumos is 100% free to use for consumers and always will be. Instead of charging customers or marking up prices, we earn a commission from the insurance company whenever you buy a plan through us. Our plans are priced exactly the same as they are on Healthcare.gov</p>
            </div>
        </div>
        <h2 id="accordion-collapse-heading-2">
            <button type="button" class="flex items-center justify-between faq_width faq_hover p-4 font-medium text-left dark:text-lumos-blue text-lumos-blue faq_question border border-b-0 border-gray-400" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
            <span>What states does Lumos support?</span>
            <svg data-accordion-icon class="faq_arrow rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
            <div class="p-5 faq_width border border-b-0 border-gray-200">
            <p class="mb-2 faq_answer dark:text-gray-500 text-gray-500">You are able to see recommendations and request to enroll in TX, FL, GA, NC, AZ, MO, and UT.<br></br><br></br>
            Plan recommendations are available in all other states that do not have a state-specific marketplace: <br></br>
            AL, AK, AR, DE, HI, IL, IN, IA, KS, LA, MI, MS, MO, MT, NE, NH, ND, OH, OK, OR, SC, SD, TN, UT, VA, WV, WI, WY<br></br><br></br>
            We do not support states with their own state-specific exchanges. You can find a link to your state-specific exchange <a href="https://www.healthcare.gov/marketplace-in-your-state/" id="hyperlink">here</a>.
            </p>
            </div>
        </div>
        <h2 id="accordion-collapse-heading-3">
            <button type="button" class="flex items-center justify-between faq_width faq_hover p-4 font-medium text-left dark:text-lumos-blue text-lumos-blue faq_question border border-gray-400" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
            <span>Why is Lumos the best place to shop for health insurance?</span>
            <svg data-accordion-icon class="faq_arrow rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-3" class="hidden" aria-labelledby="accordion-collapse-heading-3">
            <div class="p-5 faq_width border border-t-0 border-gray-200">
            <p class="mb-2 faq_answer dark:text-gray-500 text-gray-500">Unlike other solutions, true-cost transparency is central to our product experience. Our goal is to ensure you know how much you can expect to pay under each and every plan based on real health outcomes from hundreds of people just like you.<br></br><br></br>
                We provide you with the most grounded, personalized, and comprehensive cost estimates to ensure you are making the best financial decision. If you’d like to learn more about our cost estimation approach,  read our short blog post <a id="hyperlink" target="_blank" href="https://www.blog.lumos-health.com/blog/lumos-true-cost">here</a>. 
            </p>
            </div>
        </div>
        <h2 id="accordion-collapse-heading-4">
            <button type="button" class="flex items-center justify-between faq_width faq_hover p-4 font-medium dark:text-lumos-blue text-lumos-blue text-left faq_question border border-t-0 border-gray-400" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
            <span>What information will you ask for and why you do you need it?</span>
            <svg data-accordion-icon class="faq_arrow rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
            <div class="p-5 faq_width border border-t-0 border-gray-200">
            <p class="mb-2 faq_answer dark:text-gray-500 text-gray-500"> 
                To determine eligible plans and subsidies, we ask you the same information that you'll need to provide on healthcare.gov. This includes non-personally identifiable information such as: age, sex, household size, smoking status, and estimated income. <br></br><br></br>
                If you choose to enroll in a plan, you will need to provide an email address and your DOB so we can process your application and reach out to you if necessary. 
            </p>
            </div>
        </div>
        <h2 id="accordion-collapse-heading-5">
            <button type="button" class="flex items-center justify-between faq_width faq_hover p-4 font-medium dark:text-lumos-blue text-lumos-blue text-left faq_question border border-t-0 border-gray-400" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
            <span>Do your recommendations favor plans from certain insurers?</span>
            <svg data-accordion-icon class="faq_arrow rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
            </button>
        </h2>
        <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
            <div class="p-5 faq_width border border-t-0 border-gray-200">
            <p class="mb-2 faq_answer dark:text-gray-500 text-gray-500"> 
                No; our recommendations are company / plan agnostic. Our algorithms find plans across different categories that result in the best true cost outcomes for you. But you always have the option to review all eligible plans from all insurers, independent of Lumos recommendations.
            </p>
            </div>
        </div>
    </div>
  );
}