import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import insurersImage from '../assets/images/insurers.png'
import subsidiesImage from '../assets/images/subsidies.png'
import trueCostImage from '../assets/images/truecost.svg'
import recommendedImage from '../assets/images/recommended_mobile.png'
import enrollImage from '../assets/images/enroll.png'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

export default function SwiperComponent() {
  return (
    <>
      <Swiper pagination={true} modules={[Pagination]} className="myReasonsSwiper">
        <SwiperSlide className="mb-12">                
          <div className="second_div_reason">
          <img className="second_div_reason_img1" src={insurersImage} alt="logos of health insurance issuers"/>
            <div className="second_div_reason_text">
                Shop plans across all major insurers<br></br><p className="second_div_reason_subtext">We work with Healthcare.gov to give you access to all ACA plans at the same prices</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="mb-12">                
          <div className="second_div_reason">
            <img className="second_div_reason_img2" src={subsidiesImage} alt="logos of health insurance issuers"/>
            <div className="second_div_reason_text">
              See how much you qualify for in subsidies<br></br><p className="second_div_reason_subtext">It takes less than a minute</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="mb-12">                
          <div className="second_div_reason">
            <img className="second_div_reason_img" src={trueCostImage} alt="logos of health insurance issuers"/>
            <div className="second_div_reason_text">
            Save money by shopping based on the true cost of each plan<p className="second_div_reason_subtext">Using our propietary dataset and algorithms, we estimate
                        how much you should expect to spend in out-of-pocket expenses on each and every plan based on hundreds of individuals and families similar to you</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="mb-12">                
          <div className="second_div_reason">
            <img className="second_div_reason_img" src={recommendedImage} alt="logos of health insurance issuers"/>
            <div className="second_div_reason_text">
            Get personalized recommendations and shop across all eligible plans<p className="second_div_reason_subtext">Lumos' algorithms recommends plans with the lowest true cost across categories. Shop, review, compare true cost and coverage across these recommended plans and
            all your plans</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="mb-12">                
          <div className="second_div_reason">
            <img className="second_div_reason_img6" src={enrollImage} alt="logos of health insurance issuers"/>
            <div className="second_div_reason_text">
             Enroll only when you're ready<p className="second_div_reason_subtext">We prioritize your peace of mind and will never overwhelm you with messages. After selecting a plan, you'll receive an enrollment link, and we'll be at your service whenever you need assistance.</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}