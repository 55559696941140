import React from 'react';
import TypingEffect from './TypingEffect';
import TypingEffectMobile from './TypingEffectMobile';
import insurersImage from '../assets/images/insurers.png'
import subsidiesImage from '../assets/images/subsidies_desktop.png'
import trueCostImage from '../assets/images/truecost.svg'
import recommendedImage from '../assets/images/recommended4.png'
import enrollImage from '../assets/images/enroll.png'
import AutoplayCarousel from './AutoplayCarousel';
import Accordion from './FAQAccordion'
import SwiperComponent from './SwipeReasons';
import SwiperComponent2 from './SwipeReviews';
import {Helmet} from 'react-helmet'

function LandingPage() {
  return (
    <div>
        <Helmet>
            <title>Lumos Health: The Only Truly Personalized ACA Quoting Platform </title>
            <meta name="description" content="Lumos Health makes it easy to find the right ACA plan personalized to your needs. Shop plans based on the true cost of each plan." />
            <meta name="keywords" content="health insurance, ACA plans, marketplace plans, subsidies, true cost, obamacare" />
        </Helmet>
        <div className="first_div">
            <div className="first_div_base_hook typing_desktop">
                Picking health insurance <u>doesn't</u> have to be 
                <span className="mt-2 inline first_div_typeout">
                <TypingEffect></TypingEffect>
                </span>
                <div className="first_div_subhook">
                    Lumos makes it easy to find the plan that's <a id="first_div_lumos">right for you!</a>
                </div>
                <button
                  className="firstdiv-get-started-btn"
                  onClick={() => window.location.href='https://www.marketplaceapp.lumos-health.com'}>
                  Get started
                </button>
            </div>
            <div className="first_div_base_hook typing_mobile">
                Picking health insurance <u>doesn't</u> have to be 
                <span className="mt-2 inline first_div_typeout">
                <TypingEffectMobile></TypingEffectMobile>
                </span>
                <div className="first_div_subhook">
                    Lumos makes it easy to find the plan that's <a id="first_div_lumos">right for you!</a>
                </div>
                <button
                  className="firstdiv-get-started-btn"
                  onClick={() => window.location.href='https://www.marketplaceapp.lumos-health.com'}>
                  Get started
                </button>
            </div>
        </div>
        <div className="second_div_mobile">
            <div className="second_div_main_title">
                Here's what easy looks like
            </div>
            <SwiperComponent></SwiperComponent>
        </div>
        <div className="second_div">
            <div className="second_div_main_title">
                Here's what easy looks like
            </div>
            <div classname="second_div_easy_reasons">
                <div className="second_div_reason1">
                    <img className="second_div_reason1_img" src={insurersImage} alt="logos of health insurance issuers"/>
                    <div className="second_div_reason1_text">
                        Shop plans across all major insurers<br></br><p className="second_div_reason1_subtext">We work with Healthcare.gov to give<br></br> you access to all ACA plans at the same prices.</p>
                    </div>
                </div>
                <div className="second_div_reason2">
                    <div className="second_div_reason2_text">
                    See how much you qualify for in <br></br>subsidies<br></br><p className="second_div_reason2_subtext">It takes less than a minute.</p>
                    </div>
                    <img className="second_div_reason2_img" src={subsidiesImage} alt="Showing example of subsidies user may qualify for"/>
                </div>
                <div className="second_div_reason3">
                    <img className="second_div_reason3_img" src={trueCostImage} alt="Showing an equation of true cost"/>
                    <div className="second_div_reason3_text">
                        Save money by shopping based on the true <br></br>cost of each plan<p className="second_div_reason3_subtext">Using our propietary dataset and algorithms, we estimate 
                        how much you should expect to spend in out-of-pocket expenses on each and every plan based on hundreds of individuals and families similar to you.</p>
                    </div>
                </div>
                <div className="second_div_reason4">
                    <div className="second_div_reason4_text">
                        Get personalized recommendations <br></br> and shop across all plans<br></br><p className="second_div_reason4_subtext">Lumos' algorithms recommends plans with the lowest true cost across categories. Shop, review, compare true cost and coverage across these recommended plans and
                        all your plans</p>
                    </div>
                    <img className="second_div_reason4_img" src={recommendedImage} alt="Showing an example of personalized recommendations from the app"/>
                </div>
                <div className="second_div_reason6">
                    <img className="second_div_reason6_img" src={enrollImage} alt="Showing an example of personalized recommendations from the app"/>
                    <div className="second_div_reason6_text">
                    Enroll only when you're ready <p className="second_div_reason6_subtext">We prioritize your peace of mind and will never overwhelm you with messages. After selecting a plan, you'll receive an enrollment link, and we'll be at your service whenever you need assistance.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="third_div">
            <div className="third_div_main_title">
                Here's what our customers are saying
            </div>
            <div className="reviews_desktop">
                <AutoplayCarousel></AutoplayCarousel>
            </div>
            <div className="reviews_mobile">
                <SwiperComponent2></SwiperComponent2>
            </div>
            <div className="third_div_footer">
                Read more on <a className="ml-1" id="hyperlink" href="https://www.trustpilot.com/review/lumos-health.com" target="_blank" rel="noreferrer">Trustpilot</a>
            </div>
        </div>
        <div id="faqs-div">
        <div className="fourth_div" >
            <div className="fourth_div_main_title">
                Frequently Asked Questions
            </div>
            <div className="fourth_div_faqs">
                <Accordion></Accordion>
            </div>
        </div>
        </div>
    </div>
  );
}

export default LandingPage;
