import React from 'react';
import {Link} from "react-router-dom"
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

function DropdownMenu({ isOpen }) {
  return (
    <ul className={`menu mt-3 ${isOpen ? 'active' : ''}`} style={{ zIndex: 100, position: 'absolute' }}>
      <li className="py-1.5"><a href="https://www.marketplaceapp.lumos-health.com">Get started</a></li>
      <li className="py-1.5"><Link to="/about">About</Link></li>
      <li className="py-2"><a href="https://www.blog.lumos-health.com">Blog</a></li>
    </ul>
  );
}

export default DropdownMenu;